import {
    useEffect, useRef, useState,
} from 'react';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Cookies } from 'react-cookie-consent';
import { FadeLoader } from 'react-spinners';
import { useNavigate } from 'react-router-dom';
import Translate, { useTranslation } from '../../../components/translate';
import { checkCookieConsent } from '../../../components/analytics/Analytics';
import { useAuth, useBikes } from '../../../providers';
import { useSetState } from '../../../hooks';
import {
    CHAINRING_SIZES,
    CHAINSTAY_LENGTH,
    makeCancellable,
    RequestType,
    SRAM_500,
    SRAM_700,
    WHITE,
} from '../../../constants';

import styles from '../mobileChainLengthCalc.module.scss';
import Logger from '../../../Logger';
import ContentContainer from '../../../components/contentContainer';
import FirebaseAnalytics from '../../../components/firebaseAnalytics';
import Spinner from '../../../components/spinner';
import SelectionModal from '../modals/SelectionModal';
import ToggleSelector from '../../../components/toggleSelector';
import IdlerPullyNotice from '../modals/IdlerPullyNotice';
import IdlerPullyInfo from '../modals/IdlerPullyInfo';

interface CustomBikeProps {
    isOpen: boolean,
    setSelectedBikeType: (value: string) => void,
    setCustomBike: (value: boolean) => void,
     }

const CustomBikeMobile = ({
    isOpen = false, setSelectedBikeType, setCustomBike,
}: CustomBikeProps) => {
    const { isAuthenticated } = useAuth();
    const cookieConsent = Cookies.get('CookieConsent');
    const [state, setState] = useSetState({
        isCalculating: false,
        isFetching: false,
        isIdlerPulley: false,
        openSelectionModal: false,
        selectedChainRingSize: '',
        selectedChainStayLength: '',
        selectedFrameType: 'HARDTAIL',
        showInfoModal: false,
    });
    const [modalView, setModalView] = useState('');
    const {
        isCalculating,
        isFetching,
        isIdlerPulley,
        selectedChainRingSize,
        selectedChainStayLength,
        selectedFrameType,
        openSelectionModal,
    } = state;

    const bikes = useBikes();
    const navigate = useNavigate();
    const translate = useTranslation();
    const fetchChainLengthRequest = useRef<RequestType | null>(null);
    const fetchFrameCategoriesRequest = useRef<RequestType | null>(null);

    const disableCalcButton = isCalculating
                    || isFetching
                    || !selectedChainRingSize
                    || !selectedChainStayLength;

    const resetState = () => {
        setState({
            chainLength: '',
            chainStayLength: '',
            isCalculating: false,
            isIdlerPulley: false,
            openSelectionModal: false,
            selectedBikeType: '',
            selectedChainRingSize: '',
            selectedChainStayLength: '',
            showBikeSetup: false,
            showInfoModal: false,
        });
        setCustomBike(false);
        setSelectedBikeType('');
    };

    useEffect(() => {
        setState({
            selectedChainRingSize: '',
            selectedChainStayLength: '',
            selectedFrameType: 'HARDTAIL',
        });

        return (() => {
            if (fetchChainLengthRequest.current) {
                fetchChainLengthRequest.current.cancel();
                fetchChainLengthRequest.current = null;
            }

            if (fetchFrameCategoriesRequest.current) {
                fetchFrameCategoriesRequest.current.cancel();
                fetchFrameCategoriesRequest.current = null;
            }
        });
    }, [isOpen]);

    async function eventLogger() {
        // Log events to GA and Logger
        const eventFields = {
            ChainStayLength: selectedChainStayLength,
            CustomChainringSize: selectedChainRingSize,
        };

        const properties = {
            ...eventFields,
            action: 'Custom Chainlength Calculation',
            category: 'Form Submission',
            label: 'Chainlength Calculated on Submit',
        };

        FirebaseAnalytics('Custom_Chainlength_Calculation', properties);

        if (window.woopra) {
            window.woopra.track('Custom_Chainlength_Calculation', properties);
        }

        Logger.log('CustomChainLengthFormFields', eventFields);
    }

    async function fetchOEMFrameCategories(category: number) {
        if (!category) return null;

        fetchFrameCategoriesRequest.current = makeCancellable(bikes.fetchOEMFrameCategories(category));

        try {
            const data = await fetchFrameCategoriesRequest.current.promise;

            fetchFrameCategoriesRequest.current = null;

            if (data) {
                if (checkCookieConsent(cookieConsent) || isAuthenticated()) eventLogger();

                return data;
            }
        } catch (error: any) {
            if (!error.isCancelled) {
                fetchFrameCategoriesRequest.current = null;
                setState({ isCalculating: false });
            }
        }
        return null;
    }

    async function fetchChainLengths() {
        if (!selectedChainStayLength) return null;

        setState({ isCalculating: true });
        const chainStayValue = Number(selectedChainStayLength.split('mm')[0]);
        fetchChainLengthRequest.current = makeCancellable(bikes.fetchChainLengths(
            chainStayValue,
            CHAINRING_SIZES[CHAINRING_SIZES.length - 1].value,
        ));

        try {
            const data = await fetchChainLengthRequest.current.promise;

            fetchChainLengthRequest.current = null;

            const selectedChainLength = data[selectedChainRingSize.split('T')[0]];

            const supportedChainrings: any = [];
            Object.entries(data).forEach(([key, value]) => {
                if (value === selectedChainLength) {
                    supportedChainrings.push(`${key}T`);
                }
            });

            if (!data) return null;

            if (selectedChainRingSize) {
                return { chainLength: selectedChainLength, supportedChainRings: supportedChainrings };
            }
        } catch (error: any) {
            if (!error.isCancelled) {
                fetchChainLengthRequest.current = null;
            }
        }
        return null;
    }

    async function calculateChainLength() {
        const chainLengthData = await fetchChainLengths();

        const category = 1;
        const frameCategories = await fetchOEMFrameCategories(category);

        if (chainLengthData && frameCategories) {
            // eslint-disable-next-line max-len
            navigate(`/guides/mobile/fullmount/chain/calculator/setup?driveTrainType=EAGLE_TRANSMISSION&chainLength=${chainLengthData.chainLength}&chainStayLength=${Number(selectedChainStayLength.split('mm')[0])}&setupCogPosition=${frameCategories.setup_cog_position}&setupCogPositionText=${frameCategories.setup_cog_position_text}&setupKey=${frameCategories.setup_key_position}&setupKeyImage=${frameCategories.setup_key_position_image}&supportedChainRings=${chainLengthData.supportedChainRings.join(',')}&frameType=${selectedFrameType}`);
            setState({ isCalculating: false, isFetching: false });
        }
    }

    const getOptionsList = () => {
        switch (modalView) {
            case 'CHAINRING_SIZE':
                return CHAINRING_SIZES;
            case 'CHAINSTAY_LENGTH':
                return CHAINSTAY_LENGTH;
            default:
                return [];
        }
    };

    const handleSetState = (value: any) => {
        if (!value) return;
        switch (modalView) {
            case 'CHAINRING_SIZE':
                setState({ selectedChainRingSize: value.label });
                break;
            case 'CHAINSTAY_LENGTH':
                setState({ selectedChainStayLength: value.label });
                break;
            default:
                break;
        }
    };

    const renderOptions = (sectionHeader: string, value: string | number, disabled?: boolean) => {
        const onClick = () => {
            setState({ openSelectionModal: true });
            setModalView(sectionHeader);
        };

        return (
            <ContentContainer className={`${styles.optionsContainer} ${disabled ? styles.disabled : ''}`}>
                <div
                    role="button"
                    className={styles.row}
                    tabIndex={0}
                    onClick={() => {
                        if (!disabled) {
                            onClick();
                        }
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && !disabled) {
                            onClick();
                        }
                    }}
                >
                    <div className={styles.selectorTitle}>
                        <Translate>{sectionHeader}</Translate>
                    </div>
                    <div className={styles.selectorTitle}>
                        <Translate>{value || '-- --'}</Translate>
                    </div>
                </div>
            </ContentContainer>
        );
    };

    const renderToggleSwitch = (sectionHeader: string, value: string) => {
        const buttons = sectionHeader === 'FRAME_TYPE' ? [
            { label: translate('FULL_SUSPENSION'), value: 'FULL_SUSPENSION' },
            { label: translate('HARDTAIL'), value: 'HARDTAIL' },
        ] : [
            { label: translate('YES'), value: true },
            { label: translate('NO'), value: false },
        ];

        const onToggle = (toggleValue: string) => {
            switch (sectionHeader) {
                case 'FRAME_TYPE':
                    setState({ selectedFrameType: toggleValue });
                    break;
                case 'IDLER_PULLEY':
                    setState({ isIdlerPulley: toggleValue });
                    break;
                default:
                    break;
            }
        };

        return (
            <ContentContainer key={sectionHeader} className={styles.optionsContainer}>
                <div
                    role="button"
                    className={styles.row}
                    tabIndex={0}
                >
                    <div className={styles.selectorTitle}>
                        <Translate>{sectionHeader}</Translate>
                        {sectionHeader === 'IDLER_PULLEY' && (
                            <button
                                className={styles.helpButton}
                                id="infoButton"
                                type="button"
                                onClick={() => setState({ showInfoModal: true })}
                            >
                                <InfoOutlinedIcon />
                            </button>
                        )}
                    </div>
                    <div className={styles.selectorTitle}>
                        <ToggleSelector
                            activeButton={value}
                            buttonClassName={styles.toggleButton}
                            buttons={buttons}
                            color={SRAM_700}
                            className={styles.toggler}
                            onToggle={(toggleValue) => onToggle(toggleValue)}
                        />
                    </div>
                </div>
            </ContentContainer>
        );
    };

    if (!isOpen) {
        return null;
    }

    return (
        <>
            { isFetching && <Spinner Component={FadeLoader} margin={-12} height={6} width={2} />}
            <>
                <IdlerPullyNotice
                    open={isIdlerPulley}
                    onClose={() => setState({ isIdlerPulley: false })}

                />
                <IdlerPullyInfo
                    open={state.showInfoModal}
                    onClose={() => setState({ showInfoModal: false })}
                />
                { isCalculating && <Spinner Component={FadeLoader} margin={-12} height={6} width={2} />}
                <SelectionModal
                    open={openSelectionModal}
                    modalTitle={modalView}
                    onClose={() => setState({ openSelectionModal: false })}
                    setOption={handleSetState}
                    list={getOptionsList()}
                />
                {renderOptions('BIKE_BRAND', 'No Bike Listed', true)}
                {renderOptions('BIKE_MODEL', 'No Model Listed', true)}
                <div className={styles.customBikeInfo}>
                    {/* eslint-disable-next-line max-len */}
                    <Translate>If your bike model is not in our database, you will need to find or measure the chainstay length of your bike. Enter the chainstay length and chainring size below</Translate>
                </div>
                {renderOptions('CHAINSTAY_LENGTH', selectedChainStayLength)}
                {renderOptions('CHAINRING_SIZE', selectedChainRingSize)}
                {renderToggleSwitch('FRAME_TYPE', selectedFrameType)}
                {selectedFrameType === 'FULL_SUSPENSION' && renderToggleSwitch('IDLER_PULLEY', isIdlerPulley)}
                <div className={styles.formBtns}>
                    <div className={styles.footer}>
                        <button
                            className={styles.submitButton}
                            type="button"
                            onClick={() => calculateChainLength()}
                            disabled={disableCalcButton}
                            style={{ backgroundColor: disableCalcButton ? SRAM_500 : WHITE }}
                        >
                            <Translate>CALCULATE</Translate>
                        </button>
                        <button
                            className={styles.resetBtn}
                            type="button"
                            onClick={() => resetState()}
                        >
                            <Translate>RESET</Translate>
                        </button>
                    </div>
                </div>
            </>
        </>
    );
};

export default CustomBikeMobile;
